import React, { useState } from 'react';
import { ErrorBox } from '../Utils/ErrorBox';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ResetPassword } from '../../api/AuthenticationAPI';

export const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (valid) {
      ResetPassword(email)
        .then((response) => {
          if (response.success)
            setSubmitted(true)
          else
            setError(response.message)
        })
    }
  }

  const resetForm = (
    <Container>
      <Row className='d-flex justify-content-center'>
        <Col xl={6} md={9} xs={12}>
          <ErrorBox errorMessage={error} />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col xl={6} md={9} xs={12}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type='email'
                onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Row>
              <Col xs='auto'><Button type='submit'>Reset Password</Button></Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )

  const formSubmitted = (
    <Container>
      <Row className='d-flex justify-content-center'>
        <Col xl={6} md={9} xs={12}>
          Request successfully processed! If the email address is found in our system, you will receive an email with your temporary password.
        </Col>
      </Row>
    </Container>
  )

  const render = () => (
    <>
      <h1>Reset Password</h1>
      <hr />
      {!submitted
        ? resetForm
        : formSubmitted}
    </>
  )

  return render();
}