import React, { useState } from 'react';
import { ErrorBox } from '../Utils/ErrorBox';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { UpdatePassword, UpdatePasswordWithToken } from '../../api/UserAPI';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../Utils/Authorization';

export const ChangePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken, userID } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (newPassword !== repeatPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!!location.state['token'])
      UpdatePasswordWithToken(newPassword, location.state['token'])
        .then((response) => {
          if (response.success) {
            sessionStorage.setItem('spm-token', location.state['token'])
            setToken(location.state['token'])
            navigate('/')
          }
          else {
            setError(response.message)
            return
          }
        })
    if (!!location.state['id'])
      UpdatePassword(newPassword)
        .then((response) => {
          if (response.success) {
            navigate(`/user/edit?id=${userID}`)
          }
          else {
            setError(response.message)
            return
          }
        })
  }

  const render = () => {
    return !!location.state['token'] || !!userID
    ? <>
      <h1>Change Password</h1>
      <hr />
      <Container>
        <Row className='d-flex justify-content-center'>
          <Col xl={6} md={9} xs={12}>
            <ErrorBox errorMessage={error} />
          </Col>
        </Row>
        <Row className='d-flex justify-content-center'>
          <Col xl={6} md={9} xs={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  required
                  type='password'
                  onChange={(e) => setNewPassword(e.target.value)} />
              </Form.Group><Form.Group className='mb-3'>
                <Form.Label>Repeat Password</Form.Label>
                <Form.Control
                  required
                  type='password'
                  onChange={(e) => setRepeatPassword(e.target.value)} />
              </Form.Group>
              <Row>
                <Col xs='auto'><Button type='submit'>Save</Button></Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
    : <Navigate to={'/'} />
  }

  return render();
}