import React from 'react';

export const ErrorBox = ({errorMessage}) => {
  
  const render = () => {
    if (Boolean(errorMessage))
      return (
        <div className='errorBox text-center'>
          {errorMessage}
        </div>
      )
    else
        return null;
  }
  
  return render();

}