import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import { useToast } from './Utils/Toast';

export const Layout = (props) => {
  const toast = useToast();

  const render = () => (
    <>
      <NavMenu />
      <Container>
        {props.children}
      </Container>
      <Footer />
      {toast.toastContainer}
    </>
  )

  return render();
}
