const APIRequest = (params) => {
    return new Promise((resolve, reject) => {
        let spmToken = localStorage.getItem("spm-token");
        if (spmToken === null)
        spmToken = sessionStorage.getItem("spm-token");
        const headers = {
            'Accept': params.respType === 'raw' ? 'blob' : 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        };

        if (spmToken !== null)
            headers['Authorization'] = `Bearer ${spmToken}`

        fetch(`/api/${params.url}`, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject(response);
            }
            if (!response.ok) {
                throw Error(response)
            }
            if (params.respType === "raw") {
                return response.blob();
            }
            return response.json();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}
export default APIRequest;

export const APIRequestWithToken = (params) => {
    return new Promise((resolve, reject) => {
        const headers = {
            'Accept': params.respType === 'raw' ? 'blob' : 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
        };
        headers['Authorization'] = `Bearer ${params.token}`

        fetch(`/api/${params.url}`, {
            method: params.method,
            headers: headers,
            body: JSON.stringify(params.body)
        }).then(response => {
            if (response.status === 401) {
                reject(response);
            }
            if (!response.ok) {
                throw Error(response)
            }
            if (params.respType === "raw") {
                return response.blob();
            }
            return response.json();
        }).then(data => {
            resolve(data);
        }).catch(error => {
            reject(error);
        });
    });
}