import APIRequest from './APIRequest';

export const GetServiceProviders = () =>
  APIRequest({
    url: 'ServiceProvider/GetServiceProviders',
    method: 'get'
  });

export const GetServiceProvider = (serviceProviderID) =>
  APIRequest({
    url: `ServiceProvider/GetServiceProvider?id=${serviceProviderID}`,
    method: 'get',
  });

export const CreateServiceProvider = (name, contactName, description, url, serviceTypeID, contacts, active) =>
  APIRequest({
    url: 'ServiceProvider/CreateServiceProvider',
    method: 'post',
    body: {
      serviceProvider: {
        serviceProviderID: 0,
        name: name,
        contactName: contactName,
        description: description,
        url: url,
        active: active,
        serviceTypeID: Number(serviceTypeID),
        contacts: contacts
      }
    }
  })

export const UpdateServiceProvider = (serviceProviderID, name, contactName, description, url, serviceTypeID, contacts, active) =>
  APIRequest({
    url: 'ServiceProvider/UpdateServiceProvider',
    method: 'post',
    body: {
      serviceProvider: {
        serviceProviderID: Number(serviceProviderID),
        name: name,
        contactName: contactName,
        description: description,
        url: url,
        active: Boolean(active),
        serviceTypeID: Number(serviceTypeID),
        contacts: contacts
      }
    }
  })

export const DeleteServiceProvider = (serviceProviderID) =>
  APIRequest({
    url: `ServiceProvider/DeleteServiceProvider?id=${Number(serviceProviderID)}`,
    method: 'delete',
  });
