import APIRequest from './APIRequest';

export const GetServiceTypes = () =>
  APIRequest({
    url: 'ServiceType/GetServiceTypes',
    method: 'get'
  })

export const UpdateServiceTypes = (serviceTypes) =>
  APIRequest({
    url: 'ServiceType/UpdateServiceTypes',
    method: 'post',
    body: {
      serviceTypes: serviceTypes
    }
  })