import React, { createContext, useState, useMemo, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from './Loader';
import jwtDecode from 'jwt-decode';

export const AuthorizationContext = createContext();

export const Authorization = (props) => {
  const [token, setToken] = useState(sessionStorage.getItem('spm-token') ?? '');
  const [roleID, setRoleID] = useState(0);
  const [userID, setUserID] = useState(0);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);

  const getAuth = () => {
    if (!!token) {
      var user = jwtDecode(token);
      setUserID(Number(user.primarysid))
      setRoleID(Number(user.role));
      setName(user.unique_name);
    }
    else {
      setUserID(0);
      setRoleID(0);
      setName('');
    }
    setLoading(false)
  }

  useEffect(() => getAuth(), [token])

  const render = () => (
      <Loader loading={loading}>
        <AuthorizationContext.Provider value={{
          roleID: roleID,
          setRoleID: setRoleID,
          userID: userID,
          setUserID: setUserID,
          name: name,
          setName: setName,
          token: token,
          setToken: setToken,
        }}>
            {props.children}
        </AuthorizationContext.Provider>
      </Loader>
  )

  return render();

}

export const useAuth = () => {
  const authContext = useContext(AuthorizationContext);

  const isLoggedIn = useMemo(() => !!authContext.token, [authContext.token])
  const isAdmin = useMemo(() => authContext.roleID === 1, [authContext.roleID])
  const isManager = useMemo(() => authContext.roleID === 2 || authContext.roleID === 1, [authContext.roleID])
  const isProvider = useMemo(() => authContext.roleID === 3, [authContext.roleID])

  const userIs = (role = '') => {
    switch (role.toLowerCase()) {
      case 'admin':
        return isAdmin
      case 'manager':
        return isManager
      case 'provider':
        return isProvider
      default:
        return false;
    }
  }

  return {
    isAdmin,
    isManager,
    isProvider,
    userIs,
    isLoggedIn,
    setToken: authContext.setToken,
    userID: authContext.userID,
  };
}

export const Authorize = ({ role, children }) => {
  const auth = useAuth();

  const render = () => {
    if (auth.userIs(role))
      return children;
    else
      return null;
  }

  return render();
}

export const ProtectedRoute = ({ role, children }) => {
  const auth = useAuth();

  const render = () => {
    if (!!role === false)
      return children;
    else if (auth.isLoggedIn) {
      if (auth.userIs(role))
        return children;
      else
        return <Navigate to={'/'} />
    }
    else {
      return <Navigate to={'/login'} />
    }
  }

  return render();
}
