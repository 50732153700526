import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';

export const Loader = ({ loading, children }) => {
  return loading
    ? (
      <Container className='fluid loader'>
        <Row className='d-flex justify-content-center'>
          <Col xs='auto'>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              visible={true}
            />
          </Col>
        </Row>
      </Container>
    )
    : (
      <>
        {children}
      </>
    )
}