import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap'
import { Authenticate } from '../../api/AuthenticationAPI';
import { ErrorBox } from '../Utils/ErrorBox';
import { useAuth } from '../Utils/Authorization';

export const Login = (props) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    setError('');

    e.preventDefault();
    e.stopPropagation();

    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (!valid) {
      return;
    }
    else {
      Authenticate(email, password)
        .then((response) => {
          if (response.success) {
            if (response.changePassword)
              navigate('/user/changePassword', { state: { token: response.token } });
            else {
              sessionStorage.setItem('spm-token', response.token);
              auth.setToken(response.token);
              navigate('/');
            }
          }
          else {
            setError(response.message);
          }
        })
    }
  }

  const render = () => (
    <Container>
      <Row className='d-flex justify-content-center'>
        <Col xl={3} md={6} xs={9}>
          <ErrorBox errorMessage={error} />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col xl={3} md={6} xs={9}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>
                Enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
              <Form.Control.Feedback type='invalid'>
                Password is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Button className='mx-auto mb-3' type='submit'>Login</Button>
          </Form>
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col xl={3} md={6} xs={9}>
          <Link to={'/forgotPassword'}>Forgot Password</Link>
        </Col>
      </Row>
    </Container>
  )

  return render();
}