import { useAuth } from '../Utils/Authorization';
import { UserEdit } from './UserEdit';

export const UserAccount = () => {
  const auth = useAuth();
  
  const render = () => (
    <UserEdit userID={auth.userID} />
  )

  return render();
}