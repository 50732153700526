import React, { useEffect, useState } from 'react';
import { Loader } from '../Utils/Loader';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { GetUsers } from '../../api/UserAPI';

export const UserList = (props) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetUsers()
      .then((response) => {
        if (response.success)
          setUsers(response.users);
        else
          console.log(response.message);

      }).finally(() => setLoading(false))
  }, [])

  const handleCreate = () => {
    navigate('/user/new')
  }

  const render = () => (
    <>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <h1>Users</h1>
          </Col>
          <Col xs='auto' className={'ml-auto'}>
            <Button size='sm' onClick={handleCreate}>New User</Button>
          </Col>
        </Row>
      </Container>
      <hr />
      <Loader loading={loading}>
        <Table className='userList'>
          <thead>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.userID}>
                <td>{user.lastName}</td>
                <td>{user.firstName}</td>
                <td>{user.email}</td>
                <td><Link to={`/user/edit?id=${user.userID}`}>edit</Link></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Loader >
    </>
  )

  return render();
}
