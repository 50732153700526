import React, { useState, createContext, useContext } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'

const ToastContext = createContext();

export const ToastProvider = (props) => {
  const [show, setShow] = useState(false);
  const [bg, setBg] = useState('success');
  const [text, setText] = useState('');

  const render = () => (
    <ToastContext.Provider value={{
      show, setShow,
      bg, setBg,
      text, setText
    }}>
        {props.children}
    </ToastContext.Provider>
  )

  return render();
}

export const useToast = () => {
  const toastContext = useContext(ToastContext);
  const toastDelay = 3000; //ms

  const showToast = (bg, text) => {
    toastContext.setBg(bg);
    toastContext.setText(text);
    toastContext.setShow(true);
  }

  const render = () => (
    <ToastContainer className='position-fixed bottom-0 end-0 p-3' style={{ zIndex: 1 }}>
      <Toast
        bg={toastContext.bg}
        onClose={(() => toastContext.setShow(false))}
        show={toastContext.show}
        delay={toastDelay}
        autohide>
        <Toast.Body className='text-white'>{toastContext.text}</Toast.Body>
      </Toast>
    </ToastContainer>
  )

  return { toastContainer: render(), showToast }
}