import APIRequest, { APIRequestWithToken } from './APIRequest';

export const GetUsers = () =>
  APIRequest({
    url: 'User/GetUsers',
    method: 'get'
  })

export const GetUser = (userID) =>
  APIRequest({
    url: `User/GetUser?id=${Number(userID)}`,
    method: 'get'
  })

export const CreateUser = (email, firstName, lastName, active, roleID) =>
  APIRequest({
    url: 'User/CreateUser',
    method: 'post',
    body: {
      user: {
        userID: 0,
        email: email,
        firstName: firstName,
        lastName: lastName,
        active: Boolean(active),
        roleID: Number(roleID),
      }
    }
  })

export const UpdateUser = (userID, email, firstName, lastName, active, roleID) =>
  APIRequest({
    url: 'User/UpdateUser',
    method: 'post',
    body: {
      user: {
        userID: Number(userID),
        email: email,
        firstName: firstName,
        lastName: lastName,
        active: Boolean(active),
        roleID: Number(roleID),
      }
    }
  })

export const DeleteUser = (userID) =>
  APIRequest({
    url: `User/DeleteUser?id=${Number(userID)}`,
    method: 'delete'
  })

export const UpdatePassword = (password) =>
  APIRequest({
    url: 'User/ChangePassword',
    method: 'post',
    body: {
      password: password
    },
  })

export const UpdatePasswordWithToken = (password, token) =>
  APIRequestWithToken({
    url: 'User/ChangePassword',
    method: 'post',
    body: {
      password: password
    },
    token: token,
  })
