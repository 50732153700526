import { ServiceProviderEdit } from './components/ServiceProvider/ServiceProviderEdit';
import { ServiceProviderList } from './components/ServiceProvider/ServiceProviderList';
import { ServiceProviderCreate } from './components/ServiceProvider/ServiceProviderCreate';
import { Login } from './components/Authorization/Login';
import { ServiceTypeEdit } from './components/ServiceType/ServiceTypeEdit';
import { Logout } from './components/Authorization/Logout';
import { ProtectedRoute } from './components/Utils/Authorization';
import { UserList } from './components/User/UserList';
import { UserEdit } from './components/User/UserEdit';
import { UserCreate } from './components/User/UserCreate';
import { ChangePassword } from './components/Authorization/ChangePassword';
import { ForgotPassword } from './components/Authorization/ForgotPassword';
import { UserAccount } from './components/User/UserAccount';

const AppRoutes = [
  {
    index: true,
    element: <ServiceProviderList />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  {
    path: '/forgotPassword',
    element: <ForgotPassword />
  },
  {
    path: '/providers/new',
    element: (
      <ProtectedRoute role='manager'>
        <ServiceProviderCreate />
      </ProtectedRoute>
    )
  },
  {
    path: '/providers/edit',
    element: (
      <ProtectedRoute role='manager'>
        <ServiceProviderEdit />
      </ProtectedRoute>
    )
  },
  {
    path: '/serviceTypes/edit',
    element: (
      <ProtectedRoute role='manager'>
        <ServiceTypeEdit />
      </ProtectedRoute>
    )
  },
  {
    path: '/users',
    element: (
      <ProtectedRoute role='admin'>
        <UserList />
      </ProtectedRoute>
    )
  },
  {
    path: '/account',
    element: <UserAccount />
  },
  {
    path: '/user/edit',
    element: (
      <ProtectedRoute role='manager'>
        <UserEdit />
      </ProtectedRoute>
    )
  },
  {
    path: '/user/new',
    element: (
      <ProtectedRoute role='manager'>
        <UserCreate />
      </ProtectedRoute>
    )
  },
  {
    path: '/user/changePassword',
    element: <ChangePassword />
  },
  {
    path: '*',
    element: <ServiceProviderList/>
  }
];

export default AppRoutes;
