import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { ErrorBox } from '../Utils/ErrorBox'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GetRoles } from '../../api/RoleAPI'
import { DeleteUser, GetUser, UpdateUser } from '../../api/UserAPI'
import { useAuth } from '../Utils/Authorization'
import { Loader } from '../Utils/Loader'
import { useToast } from '../Utils/Toast'

export const UserEdit = (props) => {
  const navigate = useNavigate()
  const [queryParams] = useSearchParams();
  const auth = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);

  const [roles, setRoles] = useState([]);

  const [userID] = useState(props.userID ?? Number(queryParams.get('id')));
  const title = userID === auth.userID ? 'My Account' : 'Edit User';
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [active, setActive] = useState(true);
  const [roleID, setRoleID] = useState(0);

  const setState = (user) => {
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setActive(user.active);
    setRoleID(user.roleID);
  }

  useEffect(() => {
    GetUser(userID)
      .then((response) => {
        if (response.success) {
          setState(response.user);
          GetRoles()
            .then((response) => {
              if (response.success) {
                setRoles(response.roles)
                setLoading(false);
              }
              else
                console.log(response.message)
            })
        }
        else if (response.message.includes('not authorized'))
          navigate('/')
        else
          console.log(response.message);
      })

  }, [userID]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setError('');
    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (valid)
      UpdateUser(userID, email, firstName, lastName, active, roleID)
        .then((response) => {
          if (response.success){
            toast.showToast('success', 'Account updated!')
            navigate('/users')
          }
          else
            setError(response.message)
        })
  }

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete ${auth.userID === userID ? 'your account' : firstName + ' ' + lastName}?`))
      DeleteUser(userID)
        .then((response) => {
          if (response.success) {
            if (auth.userID === userID)
              navigate('/logout')
            else if (auth.isAdmin)
              navigate('/users')
            else
              navigate('/')
          }
          else
            setError(response.message)
        })
  }

  const handleCancel = () =>
    navigate('/users')

  const render = () => (
    <>
      <h1>{title}</h1>
      <hr />
      <Loader loading={loading}>
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <ErrorBox errorMessage={error} />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {auth.userID !== userID
                  ? (
                    <Form.Group className='mb-4'>
                      <Form.Check
                        type='switch'
                        label={active ? 'Active' : 'Inactive'}
                        defaultChecked={active}
                        onClick={(e) => setActive(e.target.checked)}
                      />
                    </Form.Group>
                  ) : null
                }
                <Form.Group className='mb-3'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    required
                    type='email'
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                {auth.userID === userID
                  ? (
                    <Form.Group className='mb-3'>
                      <Form.Label>Password</Form.Label>
                      <div>
                        <Button
                          variant='primary'
                          onClick={() => navigate('/user/changePassword')}>
                          Change Password
                        </Button>
                      </div>
                    </Form.Group>)
                  : null
                }
                <Form.Group className='mb-3'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
                {
                  auth.isAdmin
                    ? (
                      <Form.Group className='mb-3'>
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          required
                          as='select'
                          defaultValue={roleID}
                          onChange={(e) => setRoleID(e.target.value)}>
                          {roles.map((role) => (
                            <option key={role.id} value={role.id}>{role.value}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    )
                    : null
                }
                <Row>
                  <Col xs='auto'><Button type='submit'>Save</Button></Col>
                  <Col xs='auto'><Button variant='secondary' onClick={handleCancel}>Cancel</Button></Col>
                  <Col xs='auto'><Button variant='danger' onClick={handleDelete}>Delete</Button></Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Loader>
    </>
  )

  return render()
}