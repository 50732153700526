import React, { useState, useMemo } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useAuth } from './Utils/Authorization';

export const NavMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const auth = useAuth();

  const toggleNavbar = () =>
    setCollapsed(!collapsed);

  const elevatedNavItems = useMemo(() => {
    return (
      <>

      </>
    )
  }, [auth])

  const loginWidget = useMemo(() => {
    return auth.isLoggedIn
      ? <NavLink tag={Link} className="text-dark" to="/logout">Logout</NavLink>
      : <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>
  }, [auth.isLoggedIn])

  const render = () => (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
        <NavbarBrand tag={Link} to="/">Honeywill Service Providers</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              <NavLink tag={Link} className="text-dark" to="/">Services</NavLink>
            </NavItem>
            {auth.isManager
              ? (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/serviceTypes/edit">Service Types</NavLink>
                </NavItem>
              ) : null}
            {auth.isAdmin
              ? (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/users">Users</NavLink>
                </NavItem>
              ) : null}
            {auth.isLoggedIn
              ? (
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/account">Account</NavLink>
                </NavItem>
              ) : null}
            <NavItem>
              {loginWidget}
            </NavItem>
          </ul>
        </Collapse>
      </Navbar>
    </header>
  );

  return render();
}

