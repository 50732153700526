import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorBox } from '../Utils/ErrorBox';
import { DeleteServiceProvider, GetServiceProvider, UpdateServiceProvider } from '../../api/ServiceProviderAPI';
import { GetServiceTypes } from '../../api/ServiceTypeAPI';
import { GetContactTypes } from '../../api/ContactAPI';
import { Loader } from '../Utils/Loader';
import { useToast } from '../Utils/Toast';

export const ServiceProviderEdit = (props) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [queryParams] = useSearchParams();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [serviceProviderID] = useState(Number(queryParams.get("id")));
  const [name, setName] = useState();
  const [contactName, setContactName] = useState();
  const [description, setDescription] = useState();
  const [url, setUrl] = useState();
  const [active, setActive] = useState();
  const [serviceTypeID, setServiceTypeID] = useState();
  const [contacts, setContacts] = useState([]);

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  const setState = (serviceProvider) => {
    setName(serviceProvider.name);
    setContactName(serviceProvider.contactName);
    setDescription(serviceProvider.description);
    setUrl(serviceProvider.url);
    setActive(serviceProvider.active);
    setServiceTypeID(Number(serviceProvider.serviceTypeID));
    setContacts(serviceProvider.contacts ?? []);
  }

  useEffect(() => {
    GetServiceTypes()
      .then((response) => {
        if (response.success) {
          setServiceTypes(response.serviceTypes);
          GetContactTypes()
            .then((response) => {
              if (response.success) {
                setContactTypes(response.contactTypes);
                GetServiceProvider(Number(serviceProviderID))
                  .then((response) => {
                    if (response.success) {
                      setState(response.serviceProvider);
                      setLoading(false);
                    }
                    else if (response.message.includes('unauthorized'))
                      navigate('/')
                    else
                      console.log(response.message);
                  })
              }
              else
                console.log(response.message);
            })
        }
        else
          console.log(response.message);
      })
  }, [serviceProviderID])

  const handleContactChange = (prop, index, value) => {
    var _contacts = [...contacts];
    _contacts[index][prop] = value;
    setContacts(_contacts);
  }

  const addContact = () => {
    var _contacts = [...contacts];
    _contacts.push({
      contactID: 0,
      contactTypeID: 1,
      contactType: null,
      value: ''
    });
    setContacts(_contacts);
  }

  const removeContact = (index) => {
    var _contacts = [...contacts];
    _contacts.splice(index, 1);
    setContacts(_contacts);
  }

  const getContactValueType = (contactTypeID) => {
    switch (contactTypeID) {
      case 1: return 'text';
      case 2: return 'email';
      default: return 'text';
    }
  }

  const handleDelete = () => {
    var confirmed = window.confirm(`Are you sure you want to delete ${name}?`);
    if (confirmed)
      DeleteServiceProvider(serviceProviderID)
        .then((response) => {
          if (response.success){
            showToast('success', `Service provider "${name}" deleted.`);
            navigate('/')
          }          
          else
            setError(response.message);
        })
  }

  const handleCancel = () => {
    navigate('/');
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setError(null);

    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (valid)
      UpdateServiceProvider(
        serviceProviderID,
        name,
        contactName,
        description,
        url,
        serviceTypeID,
        contacts,
        active
      ).then((response) => {
        if (response.success) {
          setState(response.serviceProvider);
          showToast('success', `${name} successfully updated!`);
        }
        else
          setError(response.message);
      })
  }

  const render = () => (
    <>
      <h1>Edit Provider</h1>
      <hr />
      <Loader loading={loading}>
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <ErrorBox errorMessage={error} />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className='mb-4'>
                  <Form.Check
                    type='switch'
                    label={active ? 'Active' : 'Inactive'}
                    defaultChecked={active}
                    onClick={(e) => setActive(e.target.checked)}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Service Type</Form.Label>
                  <Form.Control
                    required
                    as={'select'}
                    defaultValue={serviceTypeID}
                    onChange={(e) => setServiceTypeID(Number(e.target.value))}>
                    {serviceTypes.map((st) => (
                      <option key={st.id} value={Number(st.id)}>{st.value}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)} />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Contact Name</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as={'textarea'}
                    rows={5}
                    defaultValue={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Contacts</Form.Label>
                  <Container>
                    {
                      contacts?.length === 0
                        ? <div><span className='text-muted' style={{ fontStyle: 'italic' }}>No contacts</span></div>
                        : (
                          <>
                            {contacts.map((contact, index) => (
                              <Row key={index} className='mb-1'>
                                <Col xs={3} className='d-flex justify-content-center'>
                                  <Form.Control
                                    required
                                    as='select'
                                    defaultValue={contact.contactTypeID}
                                    onChange={(e) => handleContactChange('contactTypeID', index, e.target.value)}
                                  >
                                    {contactTypes.map((ct) => (
                                      <option key={ct.id} value={ct.id}>{ct.value}</option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                <Col className='d-flex justify-content-center'>
                                  <Form.Control
                                    required
                                    type={getContactValueType(contact.contactTypeID)}
                                    defaultValue={contact.value}
                                    onChange={(e) => handleContactChange('value', index, e.target.value)} />
                                </Col>
                                <Col xs={1} className='d-flex justify-content-center align-items-center'>
                                  <Button
                                    variant='outline-danger'
                                    onClick={(e) => removeContact(index)}
                                    size='sm'
                                    style={{ height: '75%' }}>
                                    &times;
                                  </Button>
                                </Col>
                              </Row>
                            ))
                            }
                          </>
                        )
                    }
                    <Row>
                      <Col>
                        <Button
                          variant='link'
                          onClick={(e) => addContact()}>+ Add contact</Button>
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
                <Row>
                  <Col xs='auto'><Button type='submit'>Save</Button></Col>
                  <Col xs='auto'><Button variant='secondary' onClick={handleCancel}>Cancel</Button></Col>
                  <Col xs='auto'><Button variant='danger' onClick={handleDelete}>Delete</Button></Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container >
      </Loader>
    </>
  )

  return !!serviceProviderID === false
    ? <Navigate to={'/'} />
    : render()

}