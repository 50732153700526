import APIRequest from './APIRequest.js';

export const Authenticate = (email, password) =>
  APIRequest({
    url: 'Authentication/Authenticate',
    method: 'post',
    body: {
      email: email,
      password: password
    }
  })

  export const ResetPassword = (email) =>
    APIRequest({
      url: 'Authentication/ResetPassword',
      method: 'post',
      body: {
        email: email
      }
    })

export const GetRole = () =>
  APIRequest({
    url: `Authentication/GetUserRole`,
    method: 'get',
  })