import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { ErrorBox } from '../Utils/ErrorBox'
import { useNavigate } from 'react-router-dom'
import { GetRoles } from '../../api/RoleAPI'
import { CreateUser } from '../../api/UserAPI'
import { useAuth } from '../Utils/Authorization'
import { Loader } from '../Utils/Loader'
import { useToast } from '../Utils/Toast'

export const UserCreate = (props) => {
  const navigate = useNavigate()
  const auth = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);

  const [roles, setRoles] = useState([]);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [active, setActive] = useState(true);
  const [roleID, setRoleID] = useState(3);

  useEffect(() => {
    if (auth.isAdmin)
      GetRoles()
        .then((response) => {
          if (response.success) {
            setRoles(response.roles)
            setLoading(false);
          }
          else
            console.log(response.message)
        })
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setError('');
    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (valid)
      CreateUser(email, firstName, lastName, active, roleID)
        .then((response) => {
          if (response.success){
            toast.showToast('success', 'User successfully created!')
            navigate('/users')
          }
          else
            setError(response.message)
        })
  }

  const handleCancel = () =>
    navigate('/users')

  const render = () => (
    <>
      <h1>New User</h1>
      <hr />
      <Loader loading={loading}>
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <ErrorBox errorMessage={error} />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xl={6} md={9} xs={12}>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className='mb-4'>
                  <Form.Check
                    type='switch'
                    label={active ? 'Active' : 'Inactive'}
                    defaultChecked={active}
                    onClick={(e) => setActive(e.target.checked)}
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    required
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)} />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)} />
                </Form.Group>
                {
                  auth.isAdmin
                    ? (
                      <Form.Group className='mb-3'>
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          required
                          as='select'
                          defaultValue={roleID}
                          onChange={(e) => setRoleID(e.target.value)}>
                          {roles.map((role) => (
                            <option key={role.id} value={role.id}>{role.value}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    )
                    : null
                }
                <Row>
                  <Col xs='auto'><Button type='submit'>Save</Button></Col>
                  <Col xs='auto'><Button variant='secondary' onClick={handleCancel}>Cancel</Button></Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Loader>
    </>
  )

  return render()
}