import React, { useEffect, useState } from 'react';
import { GetServiceProviders } from '../../api/ServiceProviderAPI';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Loader } from '../Utils/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { Authorize } from '../Utils/Authorization';

export const ServiceProviderList = (props) => {
  const navigate = useNavigate();
  const [serviceProviders, setServiceProviders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetServiceProviders()
      .then((response) => {
        if (response.success) {
          setServiceProviders(response.serviceTypes)
        }
        else
          console.log(response.message)
      }).finally(() => setLoading(false))
  }, [])

  const formatContact = (contact) => {
    switch (contact.contactType) {
      case 'Phone':
        return (
          <>
            <a className='mobile' href={`tel:${contact.value}`}>{contact.value}</a>
            <span className='desktop'>{contact.value}</span>
          </>)
      case 'Email':
        return (<a href={`mailto:${contact.value}`}>{contact.value}</a>)
      default:
        return (<span>{contact.value}</span>)
    }
  }

  const handleCreate = () => {
    navigate('/providers/new');
  }

  const handleJump = (e) => {
    if (!!!e.target.value)
      return;

    let element = document.getElementById(e.target.value);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  const returnToTop = (
    <Button variant='link' style={{ fontSize: 'smaller' }} onClick={() => handleJump({ target: { value: 'top' } })}>Return to top</Button>
  )

  const render = () => (
    <>
      <Container>
        <Row className='align-items-center'>
          <Col>
            <h1 id='top'>Home Service Providers</h1>
          </Col>
          <Authorize role='manager'>
            <Col xs='auto' className={'ml-auto'}>
              <Button size='sm' onClick={handleCreate}>New Service Provider</Button>
            </Col>
          </Authorize>
        </Row>
      </Container>
      <hr />
      <Loader loading={loading}>
        <Container className='serviceProviderList px-0'>
          <Row className='my-2'>
            <Col>
              Here is a list of recommended service providers, If you have any problems with anyone on this list please email <a href='mailto:Linda@HoneywillTeam.com'>Linda@HoneywillTeam.com</a>.
            </Col>
          </Row>
          <Row className='my-5 d-flex align-items-center'>
            <Col xs={'auto'}><b>Jump To:</b></Col>
            <Col xs={12} lg={6}>
              <select
                className='form-control'
                onChange={(e) => handleJump(e)}
              >
                <option key={0} value={null}>-- Select a service type --</option>
                {serviceProviders.map((serviceType) => (
                  <option key={serviceType.serviceTypeID} value={serviceType.description}>{serviceType.description}</option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col lg={2} className='desktop'><h6>SERVICE PROVIDED</h6></Col>
            <Col lg={3} className='desktop'><h6>CONTACT</h6></Col>
            <Col lg={3} className='desktop'><h6>DESCRIPTION</h6></Col>
            <Col lg={3} className='desktop'><h6>WEBSITE</h6></Col>
            <Col lg={'auto'} className='desktop'></Col>
          </Row>
          {serviceProviders?.map((serviceType) => (
            <Row key={serviceType.serviceTypeID} className='mb-4'>
              <Col xs={12}>
                <Row className='serviceType align-items-center'>
                  <Col>
                    <h5 id={serviceType.description}>{serviceType.description}</h5>
                  </Col>
                  <Col xs={'auto'} className='ml-auto'>
                    {returnToTop}
                  </Col>
                </Row>
                {serviceType.serviceProviders?.map((serviceProvider) => (
                  <Row key={serviceProvider.serviceProviderID} className='serviceProvider pb-2 pt-2 px-2'>
                    <Card className={`${!serviceProvider.active ? 'inactive ' : ''}serviceProviderCard p-0 `}>
                      <Card.Header className='mobile'>
                        <Row className='d-flex justify-content-between'>
                          <Col xs={'auto'}>
                            {serviceProvider.name}
                            {!serviceProvider.active ? (<span> - <span className='inactiveLabel'>INACTIVE</span></span>) : null}
                          </Col>
                          <Col xs={'auto'}>
                            <Authorize role='manager'>
                              <Link to={`/providers/edit?id=${serviceProvider.serviceProviderID}`}>edit</Link>
                            </Authorize>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className='p-2'>
                        <Row>
                          <Col lg={2} className='desktop'>{serviceProvider.name}{!serviceProvider.active ? (<span className='inactiveLabel'><br />INACTIVE</span>) : null}</Col>
                          <Col xs={5} lg={3}>
                            {
                              !!serviceProvider.contactName
                                ? (<div>{serviceProvider.contactName}</div>)
                                : null
                            }
                            {
                              serviceProvider.contacts?.length > 0
                                ? serviceProvider.contacts.map((contact, index) => (
                                  <div key={index}>
                                    <div className='contactLabel'>{contact.contactType}:</div>
                                    <div>{formatContact(contact)}</div>
                                  </div>
                                ))
                                : null}
                          </Col>
                          <Col xs={7} lg={3} className='serviceProviderDescription'>{serviceProvider.description}</Col>
                          <Col xs={12} lg={3} className='serviceProviderUrl'>
                            {serviceProvider.url.length > 0 ? (<a href={serviceProvider.url} rel='noreferrer' target="_blank">{serviceProvider.url}</a>) : null}
                          </Col>
                          <Col xs={'auto'} className='desktop'>
                            <Authorize role='manager'>
                              <Link to={`/providers/edit?id=${serviceProvider.serviceProviderID}`}>edit</Link>
                            </Authorize>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Row>
                ))}
              </Col>
            </Row>
          ))}
        </Container>
      </Loader >
    </>
  )

  return render();
}