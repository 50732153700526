import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { ErrorBox } from '../Utils/ErrorBox';
import { GetServiceTypes, UpdateServiceTypes } from '../../api/ServiceTypeAPI';
import { Loader } from '../Utils/Loader';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../Utils/Toast'

export const ServiceTypeEdit = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [serviceTypes, setServiceTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    GetServiceTypes()
      .then((response) => {
        if (response.success)
          setServiceTypes(response.serviceTypes);
        else if (response.message.includes('unauthorized'))
          navigate('/')
        else
          console.log(response.message);
      }).finally(() => setLoading(false));
  }, [])

  const handleChange = (index, value) => {
    var _serviceTypes = [...serviceTypes];
    _serviceTypes[index].value = value;
    setServiceTypes(_serviceTypes);
  }

  const handleDelete = (index) => {
    var _serviceTypes = [...serviceTypes];
    _serviceTypes.splice(index, 1);
    setServiceTypes(_serviceTypes);
  }

  const handleCreate = () => {
    var _serviceTypes = [...serviceTypes];
    _serviceTypes.push({ id: 0, value: '' });
    setServiceTypes(_serviceTypes);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var valid = e.currentTarget.checkValidity();
    setValidated(true);

    if (valid)
      UpdateServiceTypes(serviceTypes)
        .then((response) => {
          if (response.success){
            toast.showToast('success', 'Service types updated!')
            navigate('/');
          }            
          else
            setError(response.message)
        })
  }

  const handleCancel = () => {
    navigate('/');
  }

  const render = () => (
    <>
      <h1>Service Types</h1>
      <hr/>
      <Loader loading={loading}>
        <ErrorBox errorMessage={error} />
        <Container>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className='mb-3'>
              {
                serviceTypes?.length === 0
                  ? <div><span className='text-muted' style={{ fontStyle: 'italic' }}>No service types</span></div>
                  : (
                    <>
                      {serviceTypes.map((serviceType, index) => (
                        <Row key={index} className='mb-1'>
                          <Col xl={6} md={9} xs={11} className='d-flex justify-content-center'>
                            <Form.Control
                              required
                              type='text'
                              defaultValue={serviceType.value}
                              onChange={(e) => handleChange(index, e.target.value)} />
                          </Col>
                          <Col xs={1} className='d-flex justify-content-center align-items-center'>{
                            serviceType.id === 1 ? null
                              : (
                                <Button
                                  variant='outline-danger'
                                  onClick={(e) => handleDelete(index)}
                                  size='sm'
                                  style={{ height: '75%' }}>
                                  &times;
                                </Button>)
                          }
                          </Col>
                        </Row>
                      ))
                      }
                    </>
                  )
              }
              <Row>
                <Col>
                  <Button
                    variant='link'
                    onClick={(e) => handleCreate()}>+ Add Service Type</Button>
                </Col>
              </Row>
            </Form.Group>
            <Row>
              <Col xs='auto'><Button type='submit'>Save</Button></Col>
              <Col xs='auto'><Button variant='secondary' onClick={handleCancel}>Cancel</Button></Col>
            </Row>
          </Form>
        </Container>
      </Loader>
    </>
  )

  return render();
}